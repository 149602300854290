<template>
  <div>
    <Movementmenu />
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ข้อมูลการย้าย สายการสอนและสายสนับสนุน"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <v-simple-table>
            <tbody>
              <tr>
                <td class="text-center">
                  แจ้งเงือนไข จำนวน {{ sumconditions_transfer.sum }} วิทยาลัย
                </td>
                <td class="text-center">
                  <v-chip
                    @click="conditions_transferStatuscc()"
                    color="red"
                    dark
                    outlined
                  >
                    <v-icon>mdi-alert</v-icon> แจ้งยกเลิก จำนวน
                    {{ sumconditions_transfer.sumcc }} วิทยาลัย
                  </v-chip>
                </td>
                <td class="text-center">
                  <v-chip
                    @click="conditions_transferStatusrl()"
                    color="red"
                    dark
                    outlined
                  >
                    ไม่ประสงค์รับย้าย จำนวน
                    {{ sumconditions_transfer.sumrelocated }} วิทยาลัย
                  </v-chip>
                </td>
              </tr>
              <tr>
                <td class="text-center">
                  แนบไฟล์ จำนวน
                  {{ sumconditions_transfer.sumdocpvcfile }} วิทยาลัย
                </td>

                <td class="text-center">
                  <v-chip
                    @click="conditions_transferStatusrc()"
                    color="primary"
                    dark
                    outlined
                  >
                    ตรวจสอบแล้ว จำนวน
                    {{ sumconditions_transfer.sumreceive }} วิทยาลัย
                  </v-chip>
                </td>
                <td class="text-center">
                  <v-chip
                    @click="conditions_transferStatusrcb()"
                    color="warning"
                    dark
                    outlined
                  >
                    ไม่สมบูรณ์ จำนวน
                    {{ sumconditions_transfer.sumreceive_but }} วิทยาลัย
                  </v-chip>
                </td>
              </tr>
            </tbody>
          </v-simple-table>

          <v-simple-table>
            <tbody>
              <tr>
                <td class="text-center">
                  <v-btn
                    class="ma-2"
                    color="warning"
                    @click="search_confirm()"
                    rounded
                  >
                    <v-icon>mdi-account-search</v-icon>
                    สถานศึกษายังไม่ได้ตรวจสอบ</v-btn
                  >
                </td>
                <td class="text-center">
                  <v-btn
                    class="ma-2"
                    color="red"
                    @click="search_not_submit()"
                    rounded
                  >
                    <v-icon>mdi-account-search</v-icon>
                    สถานศึกษาไม่ได้ดำเนินการ</v-btn
                  >
                </td>
                <td class="text-center">
                  <v-btn
                    class="ma-2"
                    color="red"
                    @click="search_not_submit_but()"
                    rounded
                  >
                    <v-icon>mdi-account-search</v-icon>
                    สถานศึกษา [ มีตำแหน่ง ] ไม่ได้ดำเนินการ</v-btn
                  >
                </td>
                <td class="text-center">
                  <v-btn
                    class="ma-2"
                    color="red"
                    @click="search_documentFile()"
                    rounded
                  >
                    <v-icon>mdi-account-search</v-icon>
                    สถานศึกษา ไม่ได้แนบไฟล์</v-btn
                  >
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
          single-line
          hide-details
          dense
          filled
          class="mb-2"
        />
        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="conditions_transfers"
          :search="search"
          :item-class="row_classes"
        >
          <template v-slot:item.documentsLinkfile="{ item }">
            <div v-if="item.documentsLinkfile">
              <v-icon color="red" large v-if="item.status_confirm === 'cc'"
                >mdi-information</v-icon
              ><!--    :href="'/HRvecfiles/' + item.documentsLinkfile"
                target="_blank" -->
              <v-btn
                text
                color="info"
                rounded
                @click="viewdocumentsLinkfile(item.documentsLinkfile)"
              >
                <v-icon large>mdi-printer</v-icon></v-btn
              >
              <v-btn
                color="red"
                @click="cancel_condition(item.id_ref)"
                fab
                x-small
              >
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </div>
            <div v-else>
              <h2 class="red--text">ไม่ได้แนบไฟล์</h2>
              <v-btn
                outlined
                color="info"
                @click="AddConditionFile(item.id_ref)"
                >แนบไฟล์</v-btn
              >
            </div>
          </template>

          <template v-slot:item.select_item="{ item }">
            <v-checkbox
              v-model="search"
              :value="item.college_code"
            ></v-checkbox>
          </template>

          <template v-slot:item.cancel_row="{ item }">
            <v-chip v-if="item.count_idref === '0'">
              ทำรายการไม่สำเร็จ
            </v-chip>

            <v-chip v-else-if="item.action_sub === 'not_submit'">
              ไม่ได้ทำรายการ
            </v-chip>

            <v-chip v-else-if="item.action_sub === 'not_submit_but'">
              ไม่ได้ทำรายการ(มีอัตราว่าง)
            </v-chip>

            <v-btn
              v-else
              color="red"
              @click="delete_condition(item.id_ref)"
              fab
              x-small
            >
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:item.date_time="{ item }">
            <span v-if="item.count_idref === '0'">
              <h2 class="red--text">ยังไม่ได้ดำเนินการยืนยัน</h2>
            </span>

            <span v-else-if="item.action_sub === 'not_submit'">
              <h2 class="red--text">ไม่ได้ทำรายการ</h2>
            </span>

            <span v-if="item.action_sub === 'not_submit_but'">
              <h2 class="red--text">ไม่ได้ทำรายการมีอัตราว่าง</h2>
            </span>

            <span v-else>
              {{ item.date_time }}
            </span>
          </template>

          <template v-slot:item.status_confirm="{ item }">
            <v-chip
              v-if="item.status_confirm === 'receive'"
              color="green"
              @click="receive_cancel(item.id_ref)"
              dark
            >
              ได้รับแล้ว
            </v-chip>

            <v-chip
              v-else-if="item.status_confirm === 'receive_but'"
              color="warning"
              @click="receive_cancel(item.id_ref)"
              dark
            >
              ไม่สมูรณ์
            </v-chip>

            <v-chip
              v-else-if="item.status_confirm === 'dt_receive'"
              color="red"
              @click="receive_cancel(item.id_ref)"
              dark
            >
              ไม่ได้รับ
            </v-chip>
            <v-chip
              v-else-if="item.status_confirm === 'relocated'"
              color="red"
              @click="receive_cancel(item.id_ref)"
              dark
            >
              ไม่ประสงค์รับย้าย
            </v-chip>

            <v-btn
              v-else
              color="info"
              @click="receive_status(item.id_ref)"
              fab
              x-small
            >
              <v-icon>
                mdi-message-alert
              </v-icon>
            </v-btn>
          </template>

          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!-- V-model deletecondition_transfer_dialog -->
      <v-layout>
        <v-dialog
          v-model="deletecondition_transfer_dialog"
          persistent
          max-width="40%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="warning"
              icon="mdi-delete"
              title="ยกเลิกไฟล์แนบไฟล์"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text class="text_google">
              <v-card>
                <v-card-text>
                  <v-form ref="deleteconditions_transer_form" lazy-validation>
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12>
                          ยืนยันการลบรายการ
                          {{ conditions_transfers_select.id_ref }} :
                          {{ conditions_transfers_select.college_code }}
                          <h2>
                            {{ conditions_transfers_select.college_name }}
                            {{ conditions_transfers_select.documentsLinkfile }}
                          </h2>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            label="Password ?"
                            v-model="conditions_transfers_select.password_b"
                            required
                            :rules="[v => v == '0612698358']"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show1 ? 'text' : 'password'"
                            @click:append="show1 = !show1"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="deletecondition_transfer_dialog = false"
                ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
              >
              <v-btn
                large
                color="red darken-3"
                @click.stop="delete_conditions_transfer_Submit()"
                dark
              >
                <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model deleteconditiondialog -->
      <v-layout>
        <v-dialog v-model="deleteconditiondialog" persistent max-width="40%">
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="error"
              icon="mdi-delete"
              title="ลบรายการให้สถานศึกษาดำเนินการใหม่"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text class="text_google">
              <v-card>
                <v-card-text>
                  <v-form ref="deleteconditionsform" lazy-validation>
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12>
                          ยืนยันการลบรายการ
                          {{ conditions_transfers_select.id_ref }} :
                          {{ conditions_transfers_select.college_code }}
                          <h2>
                            {{ conditions_transfers_select.college_name }}
                          </h2>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            label="Password ?"
                            v-model="conditions_transfers_select.password_b"
                            required
                            :rules="[v => v == '0612698358']"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show1 ? 'text' : 'password'"
                            @click:append="show1 = !show1"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="deleteconditiondialog = false"
                ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
              >
              <v-btn
                large
                color="red darken-3"
                @click.stop="deleteconditionSubmit()"
                dark
              >
                <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- receive_dialog -->
      <v-layout>
        <v-dialog v-model="receive_dialog" persistent max-width="60%">
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="green"
              icon="mdi-clipboard-text"
              title="ได้รับเอกสารแล้ว"
              class="px-5 py-3 text_google"
            >
            </base-material-card>
            <v-card-text class="text_google">
              <v-card>
                <v-card-text>
                  <v-form ref="receive_dialog_form" lazy-validation>
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12>
                          ยืนยันรายการ
                          {{ conditions_transfers_select.id_ref }} :
                          {{ conditions_transfers_select.college_code }}
                          <h2>
                            {{ conditions_transfers_select.college_name }}
                          </h2>
                        </v-flex>
                      </v-layout>
                      <v-col cols="12" md="12">
                        <v-alert
                          class="mx-auto text-center pa-2 ma-2"
                          border="top"
                          colored-border
                          type="info"
                          elevation="2"
                        >
                          <h2>การจัดส่งเอกสาร</h2>
                          <h2>
                            <v-radio-group
                              v-model="
                                conditions_transfers_select.status_confirm
                              "
                              row
                              required
                              :rules="[v => !!v || '']"
                              align="center"
                            >
                              <v-radio value="receive">
                                <template v-slot:label>
                                  <div>
                                    <strong class="primary--text"
                                      >ได้รับแล้วสมบูรณ์</strong
                                    >
                                  </div>
                                </template>
                              </v-radio>
                              <v-radio value="receive_but">
                                <template v-slot:label>
                                  <div>
                                    <strong class="warning--text"
                                      >ได้รับแล้วแต่ไม่สมบูรณ์</strong
                                    >
                                  </div>
                                </template>
                              </v-radio>
                              <v-radio value="dt_receive">
                                <template v-slot:label>
                                  <div>
                                    <strong class="red--text"
                                      >ไม่ได้รับเอกสาร</strong
                                    >
                                  </div>
                                </template>
                              </v-radio>
                              <v-radio value="relocated">
                                <template v-slot:label>
                                  <div>
                                    <strong class="red--text"
                                      >ไม่ประสงค์รับย้าย</strong
                                    >
                                  </div>
                                </template>
                              </v-radio>
                            </v-radio-group>
                          </h2>
                        </v-alert>
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-text-field
                          v-model="conditions_transfers_select.comment_s"
                          outlined
                          label=" เอกสารที่ขาด หมายเหตุ:"
                        ></v-text-field>
                      </v-col>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="receive_dialog = false"
                ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
              >
              <v-btn large color="green" @click.stop="receive_Submit()" dark>
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- receive_cancel_dialog -->
      <v-layout>
        <v-dialog v-model="receive_cancel_dialog" persistent max-width="60%">
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="warning"
              icon="mdi-clipboard-text"
              title="ยกเลิกได้รับเอกสารแล้ว"
              class="px-5 py-3 text_google"
            >
            </base-material-card>
            <v-card-text class="text_google">
              <v-card>
                <v-card-text>
                  <v-form ref="receive_cancel_dialog_form" lazy-validation>
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12>
                          ยืนยันรายการ
                          {{ conditions_transfers_select.id_ref }} :
                          {{ conditions_transfers_select.college_code }}
                          <h2>
                            {{ conditions_transfers_select.college_name }}
                          </h2>
                        </v-flex>
                        <v-col cols="12" md="12">
                          <v-alert
                            class="mx-auto text-center pa-2 ma-2"
                            border="top"
                            colored-border
                            type="info"
                            elevation="2"
                          >
                            <h2>การจัดส่งเอกสาร</h2>
                            <h2>
                              <v-radio-group
                                v-model="
                                  conditions_transfers_select.status_confirm
                                "
                                row
                                required
                                :rules="[v => !!v || '']"
                                align="center"
                              >
                                <v-radio value="receive">
                                  <template v-slot:label>
                                    <div>
                                      <strong class="primary--text"
                                        >ได้รับแล้วสมบูรณ์</strong
                                      >
                                    </div>
                                  </template>
                                </v-radio>
                                <v-radio value="receive_but">
                                  <template v-slot:label>
                                    <div>
                                      <strong class="warning--text"
                                        >ได้รับแล้วแต่ไม่สมบูรณ์</strong
                                      >
                                    </div>
                                  </template>
                                </v-radio>
                                <v-radio value="dt_receive">
                                  <template v-slot:label>
                                    <div>
                                      <strong class="red--text"
                                        >ไม่ได้รับเอกสาร</strong
                                      >
                                    </div>
                                  </template>
                                </v-radio>

                                <v-radio value="relocated">
                                  <template v-slot:label>
                                    <div>
                                      <strong class="red--text"
                                        >ไม่ประสงค์รับย้าย</strong
                                      >
                                    </div>
                                  </template>
                                </v-radio>
                              </v-radio-group>
                            </h2>
                          </v-alert>
                        </v-col>
                        <v-col cols="12" md="12">
                          <v-text-field
                            v-model="conditions_transfers_select.comment_s"
                            outlined
                            label=" เอกสารที่ขาด หมายเหตุ:"
                          ></v-text-field>
                        </v-col>
                      </v-layout>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="receive_cancel_dialog = false"
                ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
              >
              <v-btn
                large
                color="warning"
                @click.stop="receive_cancel_Submit()"
                dark
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;แก้ไข
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- FileCondition -->
      <!-- V-model Filecondition_transfer_dialog -->
      <v-layout>
        <v-dialog
          v-model="Filecondition_transfer_dialog"
          persistent
          max-width="40%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="info"
              icon="mdi-clipboard"
              title="แนบไฟล์"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text class="text_google">
              <v-card>
                <v-card-text>
                  <v-form
                    ref="Filecondition_transfer_dialogform"
                    lazy-validation
                  >
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12>
                          ยืนยันการลบรายการ
                          {{ conditions_transfers_File.id_ref }} :
                          {{ conditions_transfers_File.college_code }}
                          <h2>
                            {{ conditions_transfers_File.college_name }}
                          </h2>
                        </v-flex>

                        <v-flex md12>
                          <v-file-input
                            v-model="documentsLinkfile"
                            accept=".pdf"
                            name="documentsLinkfile"
                            color="deep-purple accent-4"
                            counter
                            label="ไฟล์ .pdf"
                            placeholder="Select your files"
                            prepend-icon="mdi-paperclip"
                            outlined
                            rounded
                            :show-size="1000"
                          >
                            <template v-slot:selection="{ index, text }">
                              <v-chip
                                v-if="index < 2"
                                color="deep-purple accent-4"
                                dark
                                label
                                small
                              >
                                {{ text }}
                              </v-chip>

                              <span
                                v-else-if="index === 2"
                                class="text-overline grey--text text--darken-3 mx-2"
                              >
                                +{{ files.length - 2 }} File(s)
                              </span>
                            </template>
                          </v-file-input>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="Filecondition_transfer_dialog = false"
                ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
              >
              <v-btn
                large
                color="success"
                @click.stop="FileconditionSubmit()"
                dark
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model pdfdocumentsLinkfileDialog -->
      <v-layout row justify-center>
        <v-dialog v-model="pdfdocumentsLinkfileDialog" max-width="80%">
          <v-card class="" elevation="2">
            <embed
              :src="'/HRvecfiles/' + pdf_files"
              width="100%"
              height="700px"
            />
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import Movementmenu from "../../components/admin/movementmenu.vue";

export default {
  data() {
    return {
      loading: false,
      ApiKey: "HRvec2021",
      valid: true,
      editconditions_transferdialog: false,
      receive_dialog: false,
      receive_cancel_dialog: false,
      deleteconditiondialog: false,
      times_select: "1",
      years_select: "2565",
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      time_ss: [1, 2],
      year_ss: [2565, 2566, 2567, 2568, 2569, 2570],
      conditions_transfers: [],
      editconditions_transfer: {},
      search: "",
      pagination: {},
      show1: false,
      headers: [
        { text: "เลือก", align: "left", value: "select_item" },
        { text: "อ้างอิง", align: "center", value: "id_ref" },
        { text: "รหัสสถานศึกษา", align: "center", value: "college_code" },
        { text: "สถานศึกษา", align: "left", value: "college_name" },
        { text: "ครั้งที่", align: "center", value: "time_s" },
        { text: "ปีที่", align: "center", value: "year_s" },
        { text: "วันที่ทำรายการ", align: "center", value: "date_time" },
        { text: "อัตราว่าง", align: "center", value: "man_power_college" },
        { text: "ไฟล์แนบ", align: "center", value: "documentsLinkfile" },
        {
          text: "สถานะ",
          align: "center",
          value: "status_confirm",
          icon: "mdi-message-alert"
        },
        {
          text: "ดำเนินการ",
          align: "center",
          value: "cancel_row",
          icon: "mdi-file-document-edit"
        }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      conditions_transferstatus: [],
      userstatus: {},
      conditions_transfers_select: [],
      deletecondition_transfer_dialog: false,
      periods: [],
      period_enable_process: "1",
      conditions_transfers_File: [],
      Filecondition_transfer_dialog: false,
      documentsLinkfile: null,
      updateFileconditions: {},
      sumconditions_transfer: {},
      pdfdocumentsLinkfileDialog: false,
      pdf_files: []
    };
  },
  async mounted() {
    /*  await this.conditions_transferQueryAll(); */
    await this.periodQuery();
    await this.conditions_transferQueryAll();
  },
  methods: {
    async viewdocumentsLinkfile(documentsLinkfile) {
      this.pdf_files = documentsLinkfile;
      this.pdfdocumentsLinkfileDialog = true;
    },

    async AddConditionFile(id_ref) {
      let result = await this.$http.post("conditions_transfer.php", {
        ApiKey: this.ApiKey,
        id_ref: id_ref
      });
      this.documentsLinkfile = null;
      this.conditions_transfers_File = result.data;
      this.Filecondition_transfer_dialog = true;
    },
    async FileconditionSubmit() {
      let result = "";
      let uploaded = null;
      this.updateFileconditions.ApiKey = this.ApiKey;
      this.updateFileconditions.id_ref = this.conditions_transfers_File.id_ref;
      if (this.$refs.Filecondition_transfer_dialogform.validate()) {
        if (this.documentsLinkfile != "") {
          let formData = new FormData();
          let filename =
            this.conditions_transfers_File.id_ref + "condition.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.documentsLinkfile);
          formData.append("filename", "../HRvecfiles/" + filename);
          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          if (result.data.status == true) {
            this.updateFileconditions.documentsLinkfile = filename;
          }
          uploaded = true;
        } else {
          uploaded = false;
        }
      }
      result = await this.$http.post(
        "conditions_transfer.update.php",
        this.updateFileconditions
      );
      if (result.data.status || uploaded) {
        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        await this.conditions_transferQueryAll();
      } else {
        if (!uploaded) {
          this.snackbar.icon = "mdi-close-network";
          this.snackbar.color = "red";
          this.snackbar.text = "ไฟล์ที่แนบไม่ถูกต้อง";
          this.snackbar.show = true;
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
      }
      this.Filecondition_transfer_dialog = false;
    },
    async delnewsdocumentsLinkfileDialog() {
      let result_delete = await this.$http.post("deletefile.php", {
        ApiKey: this.ApiKey,
        filename: "../HRvecfiles/" + this.conditions_transfers_File.newsCatImage
      });
      if (result_delete.data.status == true) {
        this.conditions_transfers_File.ApiKey = this.ApiKey;
        this.conditions_transfers_File.newsCatImage = "";
        let result = await this.$http.post(
          "conditions_transfer.update.php",
          this.conditions_transfers_File
        );

        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ลบข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "ลบข้อมูลผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        let result_doc = await this.$http.post("conditions_transfer.php", {
          ApiKey: this.ApiKey,
          id_ref: this.conditions_transfers_File.id_ref
        });
        this.conditions_transfers_File = result_doc.data;
      }
    },
    async periodQuery() {
      let result_period;
      result_period = await this.$http.post("period.php", {
        ApiKey: this.ApiKey,
        period_enable_process: "1",
        period_type: "teacher"
      });
      this.periods = result_period.data;
    },
    async cancel_condition(id_ref) {
      let result = await this.$http.post("conditions_transfer.php", {
        ApiKey: this.ApiKey,
        id_ref: id_ref
      });
      this.conditions_transfers_select = result.data;
      this.deletecondition_transfer_dialog = true;
    },
    async delete_condition(id_ref) {
      let result = await this.$http.post("conditions_transfer.php", {
        ApiKey: this.ApiKey,
        id_ref: id_ref
      });
      this.conditions_transfers_select = result.data;
      this.deleteconditiondialog = true;
    },
    async receive_status(id_ref) {
      let result = await this.$http.post("conditions_transfer.php", {
        ApiKey: this.ApiKey,
        id_ref: id_ref
      });
      this.conditions_transfers_select = result.data;
      this.receive_dialog = true;
    },
    async receive_cancel(id_ref) {
      let result = await this.$http.post("conditions_transfer.php", {
        ApiKey: this.ApiKey,
        id_ref: id_ref
      });
      this.conditions_transfers_select = result.data;
      this.receive_cancel_dialog = true;
    },
    async search_confirm() {
     
      let data = this.conditions_transfers
      let obj = {};
      let arr = [];
      data.forEach(value => {
        let documentsLinkfiles = String(value.documentsLinkfile)
        if (documentsLinkfiles.length>5 && value.status_confirm !='relocated' && value.status_confirm !='receive_but' && value.status_confirm !='receive') {
          obj["id_ref"] = value.id_ref;
          obj["college_code"] = value.college_code;
          obj["college_name"] = value.college_name;
          obj["time_s"] = value.time_s;
          obj["year_s"] = value.year_s;
          obj["date_time"] = value.date_time;
          obj["man_power_college"] = value.man_power_college;
          obj["documentsLinkfile"] = value.documentsLinkfile;
          obj["status_confirm"] = value.status_confirm;
          obj["cancel_row"] = value.cancel_row;
 
          arr.push({
            id_ref: obj["id_ref"],
            college_code: obj["college_code"],
            college_name: obj["college_name"],
            time_s: obj["time_s"],
            year_s: obj["year_s"],
            date_time: obj["date_time"],
            man_power_college: obj["man_power_college"],
            documentsLinkfile: obj["documentsLinkfile"],
            status_confirm: obj["status_confirm"],
            cancel_row: obj["cancel_row"],
     
          });
        }
      });
      this.conditions_transfers = arr;



    },
    async search_not_submit() {
      this.loading = true;
      let result = await this.$http
        .post("conditions_transfer_admin.php", {
          ApiKey: this.ApiKey,
          time_s: this.periods.period_times,
          year_s: this.periods.period_year,
          check_not_submit: "ok"
        })
        .finally(() => (this.loading = false));
      this.conditions_transfers = result.data;
    },
    async search_not_submit_but() {
      this.loading = true;
      let result = await this.$http
        .post("conditions_transfer_admin.php", {
          ApiKey: this.ApiKey,
          time_s: this.periods.period_times,
          year_s: this.periods.period_year,
          check_not_submit_but: "ok"
        })
        .finally(() => (this.loading = false));
      this.conditions_transfers = result.data;
    },
    async search_documentFile() {
      this.loading = true;
      let result = await this.$http
        .post("conditions_transfer_admin.php", {
          ApiKey: this.ApiKey,
          time_s: this.periods.period_times,
          year_s: this.periods.period_year,
          check_documentFile: "ok"
        })
        .finally(() => (this.loading = false));
      this.conditions_transfers = result.data;
    },
    async searchTimeYear() {
      this.loading = true;
      let result = await this.$http
        .post("conditions_transfer_admin.php", {
          ApiKey: this.ApiKey,
          time_s: this.times_select,
          year_s: this.years_select
        })
        .finally(() => (this.loading = false));
      this.conditions_transfers = result.data;
    },

    async conditions_transferStatuscc() {
      this.loading = true;
      let result = await this.$http
        .post("conditions_transfer_admin.php", {
          ApiKey: this.ApiKey,
          time_s: this.periods.period_times,
          year_s: this.periods.period_year,
          status_confirm: "cc"
        })
        .finally(() => (this.loading = false));
      this.conditions_transfers = result.data;
    },

    async conditions_transferStatusrc() {
      this.loading = true;
      let result = await this.$http
        .post("conditions_transfer_admin.php", {
          ApiKey: this.ApiKey,
          time_s: this.periods.period_times,
          year_s: this.periods.period_year,
          status_confirm: "receive"
        })
        .finally(() => (this.loading = false));
      this.conditions_transfers = result.data;
    },

    async conditions_transferStatusrcb() {
      this.loading = true;
      let result = await this.$http
        .post("conditions_transfer_admin.php", {
          ApiKey: this.ApiKey,
          time_s: this.periods.period_times,
          year_s: this.periods.period_year,
          status_confirm: "receive_but"
        })
        .finally(() => (this.loading = false));
      this.conditions_transfers = result.data;
    },

    async conditions_transferStatusrl() {
      this.loading = true;
      let result = await this.$http
        .post("conditions_transfer_admin.php", {
          ApiKey: this.ApiKey,
          time_s: this.periods.period_times,
          year_s: this.periods.period_year,
          status_confirm: "relocated"
        })
        .finally(() => (this.loading = false));
      this.conditions_transfers = result.data;
    },

    async conditions_transferQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("conditions_transfer_admin.php", {
          ApiKey: this.ApiKey,
          time_s: this.periods.period_times,
          year_s: this.periods.period_year
        })
        .finally(() => (this.loading = false));
      this.conditions_transfers = result.data;

      let sum = 0;
      let sumcc = 0;
      let sumreceive = 0;
      let sumreceive_but = 0;
      let sumrelocated = 0;
      let sumdocpvcfile = 0;

      let data = this.conditions_transfers;
      data.forEach(value => {
        sum = sum + 1;
        if (value.documentsLinkfile) {
          sumdocpvcfile = sumdocpvcfile + 1;
        }
        if (value.status_confirm == "cc") {
          sumcc = sumcc + 1;
        }
        if (value.status_confirm == "receive") {
          sumreceive = sumreceive + 1;
        }
        if (value.status_confirm == "receive_but") {
          sumreceive_but = sumreceive_but + 1;
        }
        if (value.status_confirm == "relocated") {
          sumrelocated = sumrelocated + 1;
        }
      });
      this.sumconditions_transfer.sum = sum;
      this.sumconditions_transfer.sumdocpvcfile = sumdocpvcfile;
      this.sumconditions_transfer.sumcc = sumcc;
      this.sumconditions_transfer.sumreceive = sumreceive;
      this.sumconditions_transfer.sumreceive_but = sumreceive_but;
      this.sumconditions_transfer.sumrelocated = sumrelocated;
    },
    async delete_conditions_transfer_Submit() {
      if (this.$refs.deleteconditions_transer_form.validate()) {
        this.conditions_transfers_select.ApiKey = this.ApiKey;
   
        let result;
        result = await this.$http.post(
          "conditions_transfer.admin.update.php",
          this.conditions_transfers_select
        );
        let resultfile = await this.$http.post("deletefile.php", {
          ApiKey: this.ApiKey,
          filename:
            "../HRvecfiles/" +
            this.conditions_transfers_select.documentsLinkfile
        });
        
        if (result.data.status == true) {
          this.period = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการลบข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.conditions_transferQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการลบผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.conditions_transferQueryAll();
        this.deletecondition_transfer_dialog = false;
      }
    },
    async deleteconditionSubmit() {
      if (this.$refs.deleteconditionsform.validate()) {
        this.conditions_transfers_select.ApiKey = this.ApiKey;
        let result;
        result = await this.$http.post(
          "conditions_transfer.delete.php",
          this.conditions_transfers_select
        );
        result = await this.$http.post("deletefile.php", {
          ApiKey: this.ApiKey,
          filename:
            "../HRvecfiles/" +
            this.conditions_transfers_select.documentsLinkfile
        });
        if (result.data.status == true) {
          this.period = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการลบข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.conditions_transferQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการลบผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.conditions_transferQueryAll();
        this.deleteconditiondialog = false;
      }
    },
    async receive_Submit() {
      if (this.$refs.receive_dialog_form.validate()) {
        this.conditions_transfers_select.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "conditions_transfer.update.php",
          this.conditions_transfers_select
        );
        if (result.data.status == true) {
          this.snackbar.icon = "mdi-font-awesome";
          this.snackbar.color = "success";
          this.snackbar.text = "บันทึกรายการเรียบร้อย";
          this.snackbar.show = true;
          this.conditions_transferQueryAll();
        } else {
          this.snackbar.icon = "mdi-close-network";
          this.snackbar.color = "red";
          this.snackbar.text = "ผิดพลาด";
          this.snackbar.show = true;
        }
        this.receive_dialog = false;
      }
    },
    async receive_cancel_Submit() {
      if (this.$refs.receive_cancel_dialog_form.validate()) {
        this.conditions_transfers_select.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "conditions_transfer.update.php",
          this.conditions_transfers_select
        );
        if (result.data.status == true) {
          this.snackbar.icon = "mdi-font-awesome";
          this.snackbar.color = "success";
          this.snackbar.text = "บันทึกรายการเรียบร้อย";
          this.snackbar.show = true;
          this.conditions_transferQueryAll();
        } else {
          this.snackbar.icon = "mdi-close-network";
          this.snackbar.color = "red";
          this.snackbar.text = "ผิดพลาด";
          this.snackbar.show = true;
        }
        this.receive_cancel_dialog = false;
      }
    },
    row_classes(item) {
      if (item.status_confirm == "cc") {
        return "yellow";
      }
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    color() {
      return "blue darken-4";
    }
  },
  components: { Movementmenu }
};
</script>
